import React from "react";

const PageContent2 = (props) => {
  const image = props.backgroundImage ? props.backgroundImage : "";

  return (
    <div id={props.itemId} style={{ position: "relative" }}>
      <div
        className={props.className}
        style={{
          position: "relative",
          zIndex: props.zIndex ? props.zIndex : null,
        }}
      >
        <div
        // className={`${props.shorten ? (props.last ? "100vh" : "61vh") : "100vh"} backgroundImage w-screen flex justify-center align-center z-30`}
          className="backgroundImage w-screen flex justify-center align-center z-30"
          style={{
            background: props.backgroundImage
              ? "transparent"
              : props.background,
            height: props.shorten ? (props.last ? "100vh" : "61vh") : "100vh",
            paddingBottom: props.last ? "40vh" : null,
            // paddingBottom: props.last === 8 ? "250px" : null
            // objectFit: "contain"
          }}
        >
          <div className="w-60 hidden tablet:block"></div>
          <div
            className="w-5/6 resp-box m-auto flex smaller-image-holder mid-line"
            style={{
              backgroundImage: 'url("' + props.smallerBackground + '")',
            }}
          >
            {props.children}
          </div>
          <div className="w-60 hidden tablet:block flex flex-col justify-center h-full align-center">
            <div className="w-full top-thin-line"></div>
            <div className="relative z-0 w-full mid-line"></div>
            <div
              className="w-full bottom-thin-line relative"
              style={{ pointerEvents: "none", zIndex: "-99" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="back-image">
        <div
          className="image-box"
          style={{ backgroundImage: 'url("' + image + '")' }}
        />
      </div>
    </div>
  );
};

export default PageContent2;
