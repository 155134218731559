import React from "react";
import PageContent from "./page-content";
import ScreenOne from "./About/screen-one";
import ScreenTwo from "./About/screen-two";
import ScreenThree from "./About/screen-three";
import ScreenFour from "./About/screen-four";
import ScreenFive from "./About/screen-five";
import ScreenSix from "./About/screen-six.js";
import ScreenSeven from "./About/screen-seven.js";
import Development from "./About/development.js";
import Work from "./About/work";
import MenuItem from "./menu";
import Overlay from "./overlay";
import Info from "./info.jsx";

const AboutView = () => {
    const [menuVisible, setVisibleMenu] = React.useState(false);
    const [scrollIndex, setIndex] = React.useState(0);
    const [ascentColor, setColor] = React.useState('#1f1f1f');

    const infoTexts = [
        {
            title: 'Info gathering & kick',
            text: 'We listen carefully and translate all of your requirements into needed technical documentation.'
        },
        {
            title: 'Gathering a team',
            text: 'We build a team of experts who are best suited for your software development needs.'
        },
        {
            title: 'Making magic',
            text: 'Our developers do what they do best - create top-notch software solutions, Of course, we also include the QA, as the end product needs to be bug-free!'
        },
        {
            title: 'Support',
            text: 'Customer service is something we take pride in. Whatever you need, we got you covered!'
        },
        {
            title: 'Regular updates',
            text: 'Customer demands are changing quickly, and your software needs to be updated accordingly, We will scale down the initial team and dedicate a support unit for the necessary updates.'
        }
    ]

    

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            let pageSize = null;
            let windowHeight = null;
            if (scrollIndex < 8) {
                windowHeight = window.scrollY / window.visualViewport.height;
            }else if ( 8 <= scrollIndex && scrollIndex < 12) {
                windowHeight = 6 + (window.scrollY - 7 * window.visualViewport.height ) / (window.visualViewport.height * 0.61);
            } else {
                windowHeight = 4 + (window.scrollY - 4 * (window.visualViewport.height * 0.61) ) / window.visualViewport.height;
            }
            pageSize = Math.round(windowHeight);

            if (pageSize > scrollIndex) {
                setIndex(pageSize)
            } else if (pageSize === 0) {
                setIndex(0)
            } else {
                return
            }
            switch (pageSize) {
                case 0:
                    setColor('#000');
                    break;
                case 1:
                    setColor("#000");
                    break;
                case 2:
                    setColor("#fff");
                    break;
                case 3:
                    setColor("#000");
                    break;
                case 4:
                    setColor("#fff");
                    break;
                case 7:
                    setColor("#fff");
                    break;
                case 13:
                    setColor("#fff");
                    break;
                default: setColor("#000");
            }
        });
        return () => {
            // console.log("Cleanup is in progress");
            window.removeEventListener('scroll', () => null);
        }
    }, [scrollIndex]);


    const navigate = (id) => {
        let scrollToTop = 0;
        if (id < 8) {
            scrollToTop = (id + 1) * window.visualViewport.height
        } else if (id > 11) {
            scrollToTop = 8 * window.visualViewport.height + 4 * window.visualViewport.height * 0.61 + (id - 11) * window.visualViewport.height;
        } else {
            scrollToTop = 8 * window.visualViewport.height + (id - 7) * window.visualViewport.height * 0.61;
        }
        window.scrollTo({left: 0, top: scrollToTop, behavior: 'smooth'});
    }

    return <>
        <PageContent background={'#EBEBEB'}>
            <ScreenOne />
        </PageContent>

        <PageContent background={'#EBEBEB'}>
            <ScreenTwo />
        </PageContent>

        <PageContent backgroundImage={'./images/three-women.jpg'}>
            <ScreenThree />
        </PageContent>

        <PageContent background={'#EBEBEB'} smallerBackground={'./images/7.jpg'} className={'section'}>
            <ScreenFour />
        </PageContent>

        <PageContent background={'yellow'} backgroundImage={'./images/gallery.jpg'}>
            <ScreenFive />
        </PageContent>

        <PageContent background={'#EBEBEB'}>
            <ScreenSix />
        </PageContent>

        <PageContent background={'#EBEBEB'}>
            <ScreenSeven />
        </PageContent>

        <PageContent backgroundImage={'./images/ladder.jpg'}>
            <Development />
        </PageContent>

        <div>
            {infoTexts.map((item, index) => {
                return (
                    <PageContent shorten={index !== 5} last={index === 4} key={index} background={'#EBEBEB'}>
                        <Info title={item.title} text={item.text} index={index + 1} />
                    </PageContent>
                )
            })}
        </div>

        <PageContent background={'yellow'} className={'section background'} backgroundImage={'./images/contact.jpg'}>
            <Work />
        </PageContent>
        
        <MenuItem visible={menuVisible} closeMenu={() => setVisibleMenu(false)} />
        <Overlay opened={menuVisible} scrollNext={() => navigate(scrollIndex)} ascentColor={ascentColor} openMenu={() => setVisibleMenu(!menuVisible)} />
    </>
}

export default AboutView;