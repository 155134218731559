const Info = (props) => {
    return (
        <div className="flex p-5 flex-col">
            <div className="flex relative items-start">
                <h1 className="relative pt-24 huge:pt-32 desktop:pt-40">
                    <span style={{color: '#DADADA'}} className="bigger-h1 font-bold text-4xl tablet:text-6xl laptop:text-8xl">
                        0{props.index}
                    </span>
                    <span className="ml-5 capitalize font-semibold color-gray text-2xl tablet:text-4xl laptop:text-5xl">
                        {props.title}
                    </span>
                    <div className="cust-width h-px bg-gray-500 absolute -left-10 bottom-0" />
                </h1>
            </div>
            <article className="pl-14 pt-8 laptop:pl-28 laptop:pt-14 font-openSans text laptop:w-2/3 text-xl tablet:text-xl huge:text-2xl">
                {props.text}
            </article>
        </div>
    )
}

export default Info;