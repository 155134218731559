import React from 'react';

const TeamText = ({ image, text, name, index, mobile }) => {
    return (
        <>
            <div className="flex team laptop:pl-20 flex-col z-10 justify-center relative tablet:justify-start laptop:justify-center gap-4 w-full">
                <div className="h-100 flex tablet:mt-0 flex flex-col medium:ml-24 p-0 tablet:ml-0 laptop:flex-row gap-5 items-center laptop:gap-36">
                    <img 
                        src={image} 
                        style={{ maxWidth: '400px'}} 
                        loading="lazy" 
                        className="hidden tablet:block laptop:w-52 huge:w-72 desktop:w-full" 
                        alt={name} />
                    <img 
                        src={mobile} 
                        style={{ maxWidth: '200px'}}
                        className="block tablet:hidden" 
                        loading="lazy" 
                        alt={name} />
                    <article className="text-white bold">
                        <h1 className="font-bold text-3xl laptop:text-3xl medium:text-4xl huge:text-5xl x:text-5xl">{name}</h1>
                        <p className="font-rale center text-xl mt-10 opacity-50 laptop:text-xl medium:text-2xl huge:text-3xl">{text}</p>
                    </article>
                </div>
            </div>
            <div id="mask" />
        </>
    );
};

export default TeamText;