import { useForm } from "@formspree/react";
import React from "react";

const SectionTwo = () => {
  const [state, handleSubmit, reset] = useForm("xbjndloa");

  const focusItem = (name) => {
    document?.getElementById(name)?.focus();
  };

  let describingText = "Describe your problem\nin the simple way possible";

  return (
    <div className="w-full laptop:flex">
      <form
        className="mr-auto h-5/6 laptop:h-full w-full gap-2 flex flex-col laptop:w-1/2"
        onSubmit={handleSubmit}
      >
        <div className="h-2/5 laptop:h-1/5 min-h-24 flex flex-col laptop:flex-row gap-2">
          <div
            className="flex-1 contact-input h-full"
            onClick={() => focusItem("subject")}
          >
            <input
              type="text"
              id="subject"
              name="Subject"
              placeholder="subject"
              autoComplete="off"
              required
            />
          </div>
          <div
            className="flex-1 contact-input h-full"
            onClick={() => focusItem("email")}
          >
            <input
              id="email"
              type="email"
              name="Email"
              placeholder="email"
              autoComplete="off"
              required
            />
          </div>
        </div>
        <div className="h-2/5 min-h-24 flex flex-col gap-2 laptop:h-1/5 laptop:flex-row">
          <div
            className="flex-1 contact-input h-full"
            onClick={() => focusItem("firstName")}
          >
            <input
              type="text"
              name="First Name"
              id="firstName"
              placeholder="first name"
              autoComplete="off"
              required
            />
          </div>
          <div
            className="flex-1 contact-input h-full"
            onClick={() => focusItem("lastName")}
          >
            <input
              type="text"
              name="Last Name"
              id="lastName"
              placeholder="last name"
              autoComplete="off"
              required
            />
          </div>
        </div>
        <div
          className="h-1/3 laptop:h-1/2 min-h-24 contact-input"
          onClick={() => focusItem("message")}
        >
          <textarea
            id="message"
            name="Message"
            placeholder={describingText}
            autoComplete="off"
            className="overflow-hidden"
            required
          />
        </div>
        <div className="h-1/6 min-h-24 text-center text-white contact-input darker">
          <button
            className="text-2xl h-full w-full"
            type="submit"
            disabled={state.submitting}
          >
            Send
          </button>
        </div>
      </form>
      <div className="mt-auto h-1/6 flex laptop:w-1/2">
        <div className="mt-2 laptop:mt-0 laptop:ml-auto contact-text flex flex-col mobile-xl:gap-3 pr-2 laptop:text-right text-xl laptop:text-3xl text-white huge:-mt-4">
          <a
            href="tel:+995599589339"
            className="text-xl laptop:text-3xl huge:text-4xl"
          >
            (+995) 599 58 93 39
          </a>
          <a
            className="font-bold text-2xl mobile-xl:pb-2 laptop:text-4xl huge:text-5xl"
            href="mailto:info@softchef.io"
          >
            {/* INFO@SOFTCHEF.IO */}
            Info@Softchef.io
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
