const ScreenFive = () => {
    return (
        <div className="w-full text-white h-full flex justify-end flex-col tablet:p-5 gap-20">
            <h1 className="laptop:w-1/2 font-extrabold text-4xl tablet:text-5xl laptop:text-6xl huge:text-8xl">We stand for quality</h1>
            <article className="huge:w-7/12 font-openSans text-xl tablet:text-xl laptop:text-2xl">
                At Softchef we are not driven by profit but rather a deep appreciation for great design, a shared passion for technology and innovation, and an insane obsession for quality
            </article>
        </div>
    )
}

export default ScreenFive;