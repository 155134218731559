import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

const Development = () => {
    return (
        <ParallaxProvider>
            <div className="w-full h-full flex items-end">
                <Parallax x={[0, 40]}>
                    <div className="pb-10 flex justify-between text-white flex-col development-text gap-1 font-bold text-3xl tablet:text-5xl laptop:text-6xl huge:text-8xl desktop:text-9xl">
                        <h1>Development</h1>
                        <h1>process</h1>
                    </div>
                </Parallax>
            </div>
        </ParallaxProvider>
    );
};

export default Development;