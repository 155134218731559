import React from 'react';

const SofterChefs = () => {
    return (
        <div className="grid items-center w-full">
            <article className="text-white text-center font-bold text-2xl tablet:text-3xl laptop:text-5xl desktop:text-6xl">
                <p>We are software chefs.</p>
                <p>and software is everywhere.</p>
            </article>
        </div>
    );
};

export default SofterChefs;