const ScreenOne = () => {
    return (
        <div className="flex w-full flex-col h-full">
            <h1 className="max-h-64 hidden tablet:block laptop:text-8xl opacity-5 font-extrabold huge:text-9xl ab-header">About Us</h1>
            <div className="flex flex-col gap-2 items-end pt-48 pr-4 justify-center">
                <div className="flex flex-col max-w-3xl pl-8 h-full justify-center">
                    <h1 className="font-extrabold text-4xl tablet:text-5xl laptop:text-6xl huge:text-8xl">What We Do</h1>
                    <article className="font-openSans text-2xl tablet:text-xl laptop:text-2xl huge:text-4xl font-normal tablet:pt-10 huge:pt-16">
                        We help clients solve problems by creating digital solutions based on their operations
                    </article>
                </div>
            </div>
        </div>
    )
}

export default ScreenOne;