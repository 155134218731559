import React from "react";
import { Link } from "react-router-dom";
import Facebook from "../socials/facebook";
import Instagram from "../socials/instagram";
import Linkedin from "../socials/linkedin";
import Logo from "../socials/logo";

const Overlay = (props) => {
  const menus = [
    { name: "Home", value: "home" },
    { name: "About Us", value: "about" },
    { name: "Team", value: "team" },
  ];

  const menuScroll = (index, item) => {
    document.getElementById(item)?.scrollIntoView();
  };

  // React.useEffect(() => {
  //     if (window.innerWidth >= 720) {
  //         setOpacity(true);
  //     }
  // }, [])

  return (
    <div className="w-screen h-screen pointer-events-none z-50 fixed left-0 top-0 flex flex-col">
      {/* <div className="mobile-line left-1/3 block absolute tablet:hidden l-60 h-full left-line" style={{ backgroundColor: props.ascentColor }}></div> */}
      {/* <div className="mobile-right right-1/3 block absolute tablet:hidden r-60 h-full right-line" style={{ backgroundColor: props.ascentColor }}></div> */}
      <div
        className="top-thin-line w-full min-h-xl flex border-b pointer-events-auto"
        style={{ borderColor: props.ascentColor }}>
        <div
          className="glassHeader w-60 grid place-items-center h-full cursor-pointer home-logo tablet:border-r"
          style={{ borderColor: props.ascentColor }}
          >
          <Link to={"/home"} onClick={props.closeMenu}>
            <Logo color={props.ascentColor} />
          </Link>
        </div>
        <nav className="glassHeader w-5/6 flex justify-end h-full">
          <ul className="laptop:flex align-center hidden gap-0 h-full">
            {menus.map((item, index) => {
              return (
                <li
                  className="pb-1 hvr-sweep-to-top font-nav top-navigation flex flex-col justify-end cursor-pointer text-center"
                  onClick={() => menuScroll(index, item)}
                  key={index}>
                  <Link to={item.value}>
                    <span
                      className="font-rale font-medium pl-2  pr-2 mb-0 text-xl"
                      style={{ color: props.ascentColor }}>
                      {item.name}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div
          className="glassHeader w-60 grid place-items-center h-full tablet:border-l"
          onClick={() => props.openMenu()}
          style={{ borderColor: props.ascentColor }}>
          <div className="w-full h-full relative burger-menu hvr-sweep-to-right grid place-items-center">
            <div className={props.opened ? "opened icon" : "icon"}>
              <div className="line top" style={{ background: props.ascentColor }}/>
              <div className="line mid" style={{ background: props.ascentColor }}/>
              <div className="line bot" style={{ background: props.ascentColor }}/>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-line w-full relative flex">
        <div 
          className="w-60 max-w-40 h-full pointer-events-none tablet:border-r" 
          style={{ borderColor: props.ascentColor }}/>
        <div className="w-5/6 pointer-events-none" />
        <div
        className="w-60 max-w-40 smaller-hidden workText-container hvr-sweep-to-bottom h-full border-l flex justify-center items-center"
        style={{
          borderColor: props.ascentColor,
          zIndex: 9999,
          position: "relative",
          pointerEvents: "all",
        }}>
        <Link
          onClick={props.scrollContact}
          to={"/home#contact"}
         >
          <div
            className="workText"
            style={{ color: props.ascentColor }}>
            Let’s Work Together
          </div>
        </Link>
        </div>
        <div
          className="w-60 smaller-showed h-full tablet:border-l"
          style={{ borderColor: props.ascentColor }}
        >
          {props.opened && (
            <div className="smaller-showed">
              <div className="icon">
                <Facebook color={props.ascentColor} />
              </div>
              <div className="icon">
                <Instagram color={props.ascentColor} />
              </div>
              <div className="icon">
                <Linkedin color={props.ascentColor} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="bottom-thin-line w-full relative min-h-xl flex border-t pointer-events-auto"
        style={{
          borderColor: props.ascentColor,
          position: "absolute",
          bottom: 0,
        }}
      >
        <div
          className="w-60 h-full flex justify-end tablet:border-r"
          style={{ borderColor: props.ascentColor }}
        >
          <div className="w-full hidden h-full gap-3 laptop:flex laptop:justify-end">
            <div className="icon">
              <Facebook color={props.ascentColor} />
            </div>
            <div className="icon">
              <Instagram color={props.ascentColor} />
            </div>
            <div className="icon">
              <Linkedin color={props.ascentColor} />
            </div>
          </div>
        </div>
        <div className="flex smaller-hidden-reversed w-5/6 flex justify-start laptop:justify-end">
          <div
            className="laptop:hidden flex w-full"
            style={{ justifyContent: props.hidden ? "flex-end" : "flex-start" }}
          >
            <div className="icon">
              <Facebook color={props.ascentColor} />
            </div>
            <div className="icon">
              <Instagram color={props.ascentColor} />
            </div>
            <div className="icon">
              <Linkedin color={props.ascentColor} />
            </div>
          </div>
          {!props.hidden && (
            <div
              className="glassHeader hidden laptop:grid place-items-center grid arrow-icon cursor-pointer custom-hover-rounded"
              style={{ borderColor: props.ascentColor }}
              onClick={() => props.scrollNext()}
            >
              <div
                className="flex justify-center items-center"
                style={{ width: "25px", height: "50px", position: "relative" }}
              >
                {/* <img src={'./icons/Arrow.svg'} alt={'down'} /> */}
                <svg width="15.038" height="44.087" viewBox="0 0 15.038 44.087" >
                  <g
                    id="Group_6"
                    data-name="Group 6"
                    transform="translate(15.038) rotate(90)"
                  >
                    <path
                      id="Line_10"
                      data-name="Line 10"
                      d="M42.412,2H0V0H42.412Z"
                      transform="translate(0 6.343)"
                      fill={props.ascentColor}
                    />
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M1.414,15.038,0,13.624l6.1-6.1L0,1.414,1.414,0,8.933,7.519Z"
                      transform="translate(35.154 0)"
                      fill={props.ascentColor}
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        {props.home && (
          <Link
            onClick={props.scrollContact}
            to={"/home#contact"}
            className="smaller-showed-reversed text-center w-full h-full"
          >
            <h1 className="text-xl">Let’s Work Together</h1>
          </Link>
        )}
        <div
          className="w-60 h-full tablet:border-l"
          style={{ borderColor: props.ascentColor }}
        />
      </div>
    </div>
  );
};

export default Overlay;