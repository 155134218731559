const HomeOne = () => {
  return (
    <>
      <div className="w-full h-full flex items-center justify-start tablet:justify-end tablet:items-end">
        <div
          className="flex flex-col w-full mb-3 laptop:w-1/2 gap-5 text-white"
          style={{ padding: "0 16px 9px 16px", lineHeight: "1.5" }}
        >
          <h1 className="font-extrabold text-3xl mobile-l:text-5xl tablet:text-6xl laptop:text-6xl medium:text-7xl huge:text-8xl">
            SOFTCHEF
          </h1>
          <article className="text-xl mobile-l:text-2xl laptop:text-3xl">
            We are chefs to create a software masterpiece from your business
            operations
          </article>
        </div>
      </div>
    </>
  );
};

export default HomeOne;