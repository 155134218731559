const ScreenThree = () => {
    return (
        <div className="w-full h-full flex items-end">
            <div className="flex text-white flex-col gap-14 pl-5 pb-10">
                <h1 className="font-extrabold text-4xl tablet:text-5xl laptop:text-6xl huge:text-8xl desktop:text-9xl">Our Mission</h1>
                <article className="font-thin font-openSans text-2xl tablet:text-xl laptop:text-2xl huge:text-3xl">We believe that we can easy your business</article>
            </div>
        </div>
    )
}

export default ScreenThree;