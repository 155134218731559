import React from 'react';

const Logo = ({color}) => {
    return (
        <div className='main-logo'>
            <svg xmlns="http://www.w3.org/2000/svg" width="52.457" height="79.56" viewBox="0 0 52.457 79.56">
                <g id="Group_5" data-name="Group 5" transform="translate(333.846 191.626) rotate(45)">
                    <rect id="Rectangle_4" data-name="Rectangle 3" fill={color} width="16.074" height="45" rx="8.037" transform="translate(-316.853 97.165)" />
                    <rect id="Rectangle_5" data-name="Rectangle 2" fill={color} width="12.074" height="26" rx="6.037" transform="translate(-330.926 97.165)" />
                    <rect id="Rectangle_6" data-name="Rectangle 1" fill={color} width="16.074" height="45" rx="8.037" transform="translate(-349 78)" />
                </g>
            </svg>
        </div>
    );
};

export default Logo;