import AnimeLogo from "../socials/animation-logo";

function Loader() {
    return (
        <div className="loader-content">
            <div className="starter">
                <div className="line left side" />
                <div className="line top side_r" />
                <div className="line right side" />
                <div className="line bottom side_r" />
            </div>
            <div className="final">
                <div className="line left side" />
                <div className="line top side_r" />
                <div className="line right side" />
                <div className="line bottom side_r" />
            </div>
            <div className="logo">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="52.457" height="79.56" viewBox="0 0 52.457 79.56">
                    <g id="Group_5" data-name="Group 5" transform="translate(333.846 191.626) rotate(45)">
                        <rect id="Rectangle_3" data-name="Rectangle 3" fill="#000" width="16.074" height="0" rx="8.037" transform="translate(-316.853 97.165)">
                            <animate attributeName="height" from="0" to="45" dur="0.7" fill="freeze" />
                        </rect>
                        <rect id="Rectangle_2" data-name="Rectangle 2" fill="#000" width="12.074" height="0" rx="6.037" transform="translate(-330.926 97.165)">
                            <animate attributeName="height" from="0" begin="0.8" to="26" dur="0.7" fill="freeze" />
                        </rect>
                        <rect id="Rectangle_1" data-name="Rectangle 1" fill="#000" width="16.074" height="0" rx="8.037" transform="translate(-349 78)">
                            <animate attributeName="height" from="0" begin="1.6" to="45" dur="0.7" fill="freeze" />
                        </rect>
                    </g>
                </svg> */}
                <AnimeLogo />
            </div>
        </div>
    );
}

export default Loader;