const ScreenFour = () => {
    return (
        <div className="w-full h-full flex justify-around flex-col tablet:p-5 laptop:p-32">
            <div className="text-white font-bold pt-10 text-4xl tablet:text-5xl laptop:text-6xl huge:text-8xl">
                <h1>Brand and</h1>
                <h1>product strategy</h1>
            </div>
            <article className="pt-20 huge:w-3/5 font-openSans text-white tablet:text-xl laptop:text-2xl huge:text-3xl mt-5">
                Our results-driven solutions are based on research and insights that support the strategic growth of your brands and products.
            </article>
        </div>
    )
}

export default ScreenFour;