const AnimeLogo = () => {
    return (
        <div className='main-logo'>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scale(0.8)' }} width="105.951" height="160.125" viewBox="0 0 105.951 160.125">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_71" data-name="Rectangle 71" width="32.2" height="90.406" rx="16.1" transform="translate(989.774 532.994) rotate(45)" />
                    </clipPath>
                    <clipPath id="clip-path-2">
                        <path id="Path_63" data-name="Path 63" d="M16.581,0a15.977,15.977,0,0,1,16.1,16.1l-.317,58.524A16.4,16.4,0,0,1,16.089,90.9,15.977,15.977,0,0,1-.011,74.8L.306,16.273A16.4,16.4,0,0,1,16.581,0Z" transform="translate(990.253 532.655) rotate(45)" />
                    </clipPath>
                    <clipPath id="clip-path-3">
                        <rect id="Rectangle_74" data-name="Rectangle 74" width="24.186" height="52.015" rx="12.093" transform="translate(947.907 513.993)" />
                    </clipPath>
                </defs>
                <g id="Group_182" data-name="Group 182" transform="translate(20354.387 952.981)">
                    <g id="Mask_Group_25" data-name="Mask Group 25" transform="translate(-21261.266 -1412.795)" clip-path="url(#clip-path)">
                        <g id="Group_179" data-name="Group 179">
                            <rect id="Rectangle_69" data-name="Rectangle 69" width="32.2" height="90.406" rx="16.1" transform="translate(1054.774 469.994) rotate(45)" />
                            <g id="Rectangle_70" data-name="Rectangle 70" transform="translate(989.774 532.994) rotate(45)" fill="none" stroke="#000" stroke-width="1">
                                <rect width="32.2" height="90.406" rx="16.1" stroke="none" />
                                <rect x="0.5" y="0.5" width="31.2" height="89.406" rx="15.6" fill="none" />
                            </g>
                        </g>
                    </g>
                    <g id="Mask_Group_26" data-name="Mask Group 26" transform="translate(-21280.357 -1485.627)" clip-path="url(#clip-path-2)">
                        <g id="Group_180" data-name="Group 180" transform="translate(925.967 469.655)">
                            <path id="Path_61" data-name="Path 61" d="M16.581,0a15.977,15.977,0,0,1,16.1,16.1l-.317,58.524A16.4,16.4,0,0,1,16.089,90.9,15.977,15.977,0,0,1-.011,74.8L.306,16.273A16.4,16.4,0,0,1,16.581,0Z" transform="translate(129.994 0) rotate(45)" />
                            <g id="Path_62" data-name="Path 62" transform="translate(64.286 63) rotate(45)" fill="none">
                                <path d="M16.581,0a15.977,15.977,0,0,1,16.1,16.1l-.317,58.524A16.4,16.4,0,0,1,16.089,90.9,15.977,15.977,0,0,1-.011,74.8L.306,16.273A16.4,16.4,0,0,1,16.581,0Z" stroke="none" />
                                <path d="M 16.67104148864746 0.997467041015625 L 16.58682823181152 0.9976959228515625 C 12.52804183959961 1.019691467285156 8.703065872192383 2.62152099609375 5.816503524780273 5.508079528808594 C 2.929941177368164 8.394645690917969 1.328117370605469 12.21961975097656 1.306116104125977 16.27840423583984 L 0.9888172149658203 74.80255889892578 C 0.9669170379638672 78.84214782714844 2.514379501342773 82.63484191894531 5.346141815185547 85.48200988769531 C 8.177879333496094 88.32914733886719 11.96110343933105 89.89713287353516 15.99891662597656 89.89713287353516 L 16.08315467834473 89.89690399169922 C 20.14194107055664 89.87490844726562 23.96691513061523 88.27307891845703 26.85347938537598 85.38652038574219 C 29.74005317687988 82.49995422363281 31.34187889099121 78.67498016357422 31.36387825012207 74.61619567871094 L 31.68117904663086 16.09203338623047 C 31.70307922363281 12.05245971679688 30.15561676025391 8.259757995605469 27.3238410949707 5.412605285644531 C 24.49209213256836 2.565460205078125 20.70885467529297 0.997467041015625 16.67104148864746 0.997467041015625 M 16.67104148864746 -0.002532958984375 C 25.57026290893555 -0.002532958984375 32.72946929931641 7.186798095703125 32.68116760253906 16.09745788574219 L 32.36386489868164 74.62162017822266 C 32.31539154052734 83.56174468994141 25.0287036895752 90.84841918945312 16.08857917785645 90.89689636230469 C 7.148483276367188 90.94538116455078 -0.0596466064453125 83.73727416992188 -0.01116943359375 74.79713439941406 L 0.3061294555664062 16.27297973632812 C 0.3545913696289062 7.332855224609375 7.641290664672852 0.046173095703125 16.5814037322998 -0.002288818359375 C 16.61087036132812 -0.00244903564453125 16.6416130065918 -0.002532958984375 16.67104148864746 -0.002532958984375 Z" stroke="none" fill="#000" />
                            </g>
                        </g>
                    </g>
                    <g id="Mask_Group_27" data-name="Mask Group 27" transform="translate(-20598.25 -1933.455) rotate(45)" clip-path="url(#clip-path-3)">
                        <g id="Group_181" data-name="Group 181">
                            <rect id="Rectangle_72" data-name="Rectangle 72" width="24.186" height="52.015" rx="12.093" transform="translate(947.907 565.993)" />
                            <g id="Rectangle_73" data-name="Rectangle 73" transform="translate(947.907 513.993)" fill="none" stroke="#000" stroke-width="1">
                                <rect width="24.186" height="52.015" rx="12.093" stroke="none" />
                                <rect x="0.5" y="0.5" width="23.186" height="51.015" rx="11.593" fill="none" />
                            </g>
                        </g>
                    </g>
                    <g id="Mask_Group_25-2" data-name="Mask Group 25" transform="translate(-21260.977 -1412.547)" clip-path="url(#clip-path)">
                        <g id="Group_179-2" data-name="Group 179">
                            <rect id="Rectangle_70-2" data-name="Rectangle 70" width="32.2" height="0" rx="16.1" transform="translate(989.774 532.994) rotate(45)">
                                <animate attributeName="height" from="0" begin="0" to="90.406" dur="0.5" fill="freeze" />
                                <animate attributeName="height" from="90.406" begin="1.8" to="0" dur="0.5" fill="freeze" />
                                <animate attributeName="height" from="0" begin="3.6" to="90.406" dur="0.2" fill="freeze" />
                            </rect>
                        </g>
                    </g>
                    <g id="Mask_Group_26-2" data-name="Mask Group 26" transform="translate(-21280.068 -1485.379)" clip-path="url(#clip-path-2)">
                        {/* <g id="Group_180-2" data-name="Group 180" transform="translate(925.967 469.655)">
                    <path id="Path_62-2" height="0" data-name="Path 62" d="M16.581,0a15.977,15.977,0,0,1,16.1,16.1l-.317,58.524A16.4,16.4,0,0,1,16.089,90.9,15.977,15.977,0,0,1-.011,74.8L.306,16.273A16.4,16.4,0,0,1,16.581,0Z" transform="translate(64.286 63) rotate(45)">
                        <animate attributeName="height" from="0" begin="0" to="90.406" dur="0.7" fill="freeze" />
                    </path>
                </g> */}
                        <g id="Group_180-2" data-name="Group 180">
                            <rect id="Rectangle_70-2" data-name="Rectangle 70" width="32.2" height="0" rx="16.1" transform="translate(989.774 532.994) rotate(45)">
                                <animate attributeName="height" from="0" begin="1.2" to="90.406" dur="0.5" fill="freeze" />
                                <animate attributeName="height" from="90.406" begin="3.0" to="0" dur="0.5" fill="freeze" />
                                <animate attributeName="height" from="0" begin="3.6" to="90.406" dur="0.2" fill="freeze" />
                            </rect>
                        </g>
                    </g>
                    <g id="Mask_Group_27-2" data-name="Mask Group 27" transform="translate(-20597.961 -1933.207) rotate(45)" clip-path="url(#clip-path-3)">
                        <g id="Group_181-2" data-name="Group 181">
                            <rect id="Rectangle_73-2" data-name="Rectangle 73" width="24.186" height="0" rx="12.093" transform="translate(947.907 513.993)">
                                <animate attributeName="height" from="0" begin="0.6" to="52.015" dur="0.5" fill="freeze" />
                                <animate attributeName="height" from="52.015" begin="2.4" to="0" dur="0.5" fill="freeze" />
                                <animate attributeName="height" from="0" begin="3.6" to="52.015" dur="0.2" fill="freeze" />
                            </rect>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default AnimeLogo;