import "tailwindcss/tailwind.css";
import React, { useState } from "react";
import PageContent from "../components/page-content";
import PageContent2 from "../components/page-content-2";
import MenuItem from "../components/menu";
import Overlay from "../components/overlay";
import HomeOne from "../components/Home/scree-one";
import HomeText from "../components/home-text";
import SectionTwo from "../components/two";
import SofterChefs from "../components/Home/softer-chefs";
import Loader from "../components/loader";
import Team from "../components/contractors";
import { ParallaxProvider } from "react-scroll-parallax";

// import homeImage from '../../public/images/'

function Home() {
  const [menuVisible, setVisibleMenu] = useState(false);
  const [scrollIndex, setIndex] = useState(0);
  const [ascentColor, setColor] = React.useState("#fff");
  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    const item = window.location.hash;
    let index;
    switch (item) {
      case "#services":
        index = 1;
        break;
      case "#contact":
        index = 8;
        break;
      case "#clients":
        index = 7;
        break;
      default:
        index = 0;
    }
    if (index) {
      window.scrollTo(0, index * window.visualViewport.height);
      return;
    }
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  React.useEffect(() => {
    const location = window.location;
    if (location.pathname === "/") {
      setLoader(true);
    } else {
      setLoader(false);
    }
    return () => null;
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      const pageSize = Math.round(
        window.scrollY / window.visualViewport.height
      );
      if (pageSize === scrollIndex) return;
      setIndex(pageSize);
      switch (pageSize) {
        case 0:
          setColor("#fff");
          break;
        case 1:
          setColor("#000");
          break;
        case 6:
          setColor("#fff");
          break;
        case 7:
          setColor("#000");
          break;
        case 8:
          setColor("#fff");
          break;
        default:
          setColor("#000");
      }
    });
    return () => window.removeEventListener("scroll", () => null);
  }, [scrollIndex]);

  const navigate = () => {
    window.scrollTo({
      left: 0,
      top: (scrollIndex + 1) * window.visualViewport.height,
      behavior: "smooth",
    });
  };

  const navigateToConact = () => {
    setVisibleMenu(false);
    window.scrollTo({
      left: 0,
      top: 8 * window.visualViewport.height,
      behavior: "smooth",
    });
  };

  const homeText = [
    {
      header: "Web",
      subHeader: "Application",
      image: "./icons/Web Application.svg",
      left: "11%",
      top: "12%",
      text: "We build software and services that are fast, secure, and modern for businesses of all sizes. Our technologies help companies grow by increasing their productivity, lowering their costs, and providing a better customer experience.",
    },
    {
      header: "Mobile",
      subHeader: "Application",
      image: "./icons/Mobile application.svg",
      left: "18%",
      top: "12%",
      text: "Starting from as little as a concept to fully-fledged mobile app development, our team can cover the entire development process Our team of expert designers, full-stack developers, quality assurance, product managers, etc, allows us to deliver well-designed and optimized custom mobile apps for both iOS and Android mobile platforms.",
    },
    {
      header: "UI/UX",
      subHeader: "Design",
      image: "./icons/designer.svg",
      left: "9%",
      top: "28%",
      text: "Take your customers on a fantastic journey with your brand from the very first second. Let your design speak. We build bridges between your business goals and customer needs.",
    },
    {
      header: "Search Engine",
      subHeader: "Optimisation",
      image: "./images/oval.svg",
      left: "10%",
      top: "12%",
      text: "Did you know that 75% of people who perform an online search don’t go past the first page of Google? If you’re not there, you’re nowhere. Our SEO team at NP Digital will help you get there. After all, up and to the right are the only directions we know. Be found by your audience.",
    },
    {
      header: "Advisory",
      subHeader: "Services",
      left: "9%",
      top: "33%",
      image: "./icons/Advisory services.svg",
      text: "We help your business achieve your organizational objectives through expert management consulting, project management, technical evaluations, analysis, and due diligence services.",
    },
  ];

  const gotoContact = () => {
    setVisibleMenu(false);
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.visualViewport.height || 0
    );
    window.scrollTo({ left: 0, top: 8 * vh, behavior: "smooth" });
  };

  const closeMenu = () => {
    setVisibleMenu(false);
  }

  const menuRes = (hash) => {
    setVisibleMenu(false);
    let index = 0;
    switch (hash) {
      case "services":
        index = 1;
        break;
      case "contact":
        index = 8;
        break;
      case "clients":
        index = 7;
        break;
      default:
        index = 1;
    }
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.visualViewport.height || 0
    );
    window.scrollTo({ left: 0, top: index * vh, behavior: "smooth" });
  };

  return (
    <>
      {loader && (
        <div className={"loader-wrapper"}>
          <Loader />
        </div>
      )}
      <ParallaxProvider>
        <div className="max-w-full text-base overflow-x-hidden mx-auto">
          <PageContent backgroundImage={'./images/home-welcome.jpg'}>
            <HomeOne />
          </PageContent>
          {homeText.map((item, index) => {
            return (
              <PageContent key={index} background={"#EBEBEB"} keyt={`${index}`}>
                <HomeText
                  index={index}
                  text={item.text}
                  left={item.left}
                  top={item.top}
                  subheader={item.subHeader}
                  image={item.image}
                  header={item.header}
                />
              </PageContent>
            );
          })}
          <div id="services">
            <PageContent backgroundImage={"./images/indian.jpg"}>
              <SofterChefs />
            </PageContent>
          </div>
          <div id="clients">
            <PageContent background={"#EBEBEB"}>
              <Team />
            </PageContent>
          </div>
          <div id="contact">
            <PageContent2 backgroundImage={"./images/async.jpg"}>
              <SectionTwo />
            </PageContent2>
          </div>
        </div>
        <MenuItem
          visible={menuVisible}
          color={ascentColor}
          closeMenu={(e) => menuRes(e)}
        />
        <Overlay
          home={!menuVisible}
          scrollContact={navigateToConact}
          opened={menuVisible}
          scrollNext={navigate}
          gotoContact={gotoContact}
          ascentColor={ascentColor}
          openMenu={() => setVisibleMenu(!menuVisible)}
          closeMenu={closeMenu}
        />
      </ParallaxProvider>
    </>
  );
}

export default Home;
