import React from 'react';

const Facebook = ({ color }) => {
    return (
        <a href={'https://www.facebook.com/SoftChefs'} target="_blank" rel="noreferrer">
            <svg id="Group_20" data-name="Group 20" xmlns="http://www.w3.org/2000/svg" width="23.925" height="23.925" viewBox="0 0 23.925 23.925">
                <path id="Path_2" data-name="Path 2" d="M20.935,0H2.991A2.994,2.994,0,0,0,0,2.991V20.935a2.994,2.994,0,0,0,2.991,2.991h8.972V15.7H8.972V11.963h2.991V8.972a4.486,4.486,0,0,1,4.486-4.486h2.991V8.224h-1.5c-.825,0-1.5-.078-1.5.748v2.991h3.738l-1.5,3.738H16.449v8.224h4.486a2.994,2.994,0,0,0,2.991-2.991V2.991A2.994,2.994,0,0,0,20.935,0Z" fill={color} />
            </svg>
        </a>
    );
};

export default Facebook;