import PageContent from "./page-content";
import {Link} from "react-router-dom";

const MenuItem = (props) => {
    const menuList = [
        {name: 'Services', path: '/home', hash: 'services'},
        {name: 'Contact', path: '/home', hash: 'contact'},
        {name: 'Clients', path: '/home', hash: 'clients'},
        {name: 'Team', path: '/team', hash: ''},
        {name: 'About', path: '/about', hash: ''},
        {name: 'Join Us', path: '/join-us', hash: ''},
    ]
    return (
        <>
            {
                props.visible ? (
                    <div className="w-screen fixed top-0 left-0 z-50 glass h-screen">
                        <PageContent zIndex={2}>
                            <menu className="h-full w-1/2 tablet:w-full">
                                <ul className="h-full w-1/2 tablet:w-full text-bold">
                                    {menuList.map(item => {
                                        return (
                                            <li className="h-1/6 w-1/2 tablet:w-full relative font-bold cursor-pointer" key={item.name}>
                                                <Link 
                                                    style={{color: props.color}} 
                                                    to={item.path + '#' + item.hash} 
                                                    onClick={() => props.closeMenu(item.hash)} 
                                                    className="absolute text-4xl w-full h-full hoverableMenu"
                                                    >
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </menu>
                        </PageContent>
                    </div>
                ) : null
            }
        </>
    )
}

export default MenuItem;
