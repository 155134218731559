import React from "react";
import { Link } from "react-router-dom";

const Work = () => {
  return (
    <div className="w-full flex items-center justify-center h-full">
      <div className="flex justify-between gap-20 pt-20 relative custom-text text-white flex-col text-center">
        <h1 className="font-rale capitalize text-4xl tablet:text-5xl laptop:text-6xl">
          Let's work Together
        </h1>
        <Link
          to={"/home#contact"}
          className="mx-auto flex justify-center w-max mobile:mt-10 mobile-l:mt-20 tablet:mt-40"
        >
          <h2 className="w-max cursor-pointer font-rale capitalize text-xl tablet:text-2xl">
            order our service
          </h2>
          <div className="custom-line"></div>
        </Link>
      </div>
    </div>
  );
};

export default Work;
