import React from 'react';
import Typed from 'react-typed';

const JoinUsText = () => {
    return (
        <div style={{color: '#fff'}} className="w-full flex flex-col relative justify-between mobile:gap-12 mobile-l:gap-0">
            <h1 className="text-3xl mobile-l:text-7xl laptop:text-8xl desktop:text-9xl tablet:opacity-20">Join Our Teams</h1>
            <div className="text-xl tablet:text-2xl laptop:text-4xl desktop:text-5xl flex gap-2 items-center justify-end tablet:p-5">
                <article className="font-rale text-right w-full tablet:w-4/5 desktop:w-3/5">
                    Email us a cover letter and your CV. We are always on the lookout for awesome people!
                </article>
            </div>
            <h1 className='mail text-xl font-bold text-right laptop:hidden tablet:text-3xl tablet:pr-5'>Info@Softchef.com</h1>
            <div className="pl-2 flex justify-between items-end">
                <div className="text-xl font-bold tablet:text-5xl laptop:text-5xl desktop:text-8xl animated-text">
                    <h1>
                        <Typed
                            cursor={true}
                            typeSpeed={100}
                            backSpeed={60}
                            strings={['Design', 'Mobile', 'Web', 'Seo', 'Management']}
                            loop
                        />
                    </h1>
                </div>
                <div className="mail pr-5 font-bold hidden text-xl laptop:block tablet:text-2xl laptop:text-4xl desktop:text-5xl">
                    <h1>
                        Info@Softchef.io
                    </h1>
                </div>
            </div>
            <div className="pl-2 text-xl tablet:text-3xl laptop:text-4xl desktop:text-5xl">
                <h2>
                    Team
                </h2>
            </div>
        </div>
    );
};

export default JoinUsText;