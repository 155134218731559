import Home from "./pages";
import './hover-min.css';
import './Home.module.css';
import './App.css';
import JoinUs from './pages/join-us/join-page';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Team from "./pages/team";
import About from "./pages/about";

// let vh = window.innerHeight * 0.01;
// document.documentElement.style.setProperty('--vh', `${vh}px`);

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/join-us">
                    <JoinUs />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/team">
                    <Team />
                </Route>
                <Route path="/">
                    <Home loader={true} />
                </Route>
                <Route path="/home">
                    <Home loader={false} />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
