const ScreenTwo = () => {
    return (
        <div className="flex items-center justify-center flex-col tablet:gap-8 huge:gap-20 text-center h-full">
            <h1 className="text-4xl tablet:text-5xl laptop:text-6xl huge:text-8xl desktop:text-9xl font-black">Our Belief</h1>
            <article className="w-5/6 font-medium font-openSans text-xl tablet:text-xl laptop:text-2xl huge:text-4xl" style={{lineHeight: '1.5'}}>
                We strive to be a team of people who maximize the overall company output by focusing on achieving personal goals and improving individual results while having enough freedom and fun!
            </article>
        </div>
    )
}

export default ScreenTwo;