import React, {useState} from 'react';
import PageContent from '../../components/page-content';
import MenuItem from '../../components/menu';
import Overlay from '../../components/overlay';
import JoinUsText from '../../components/joinus-text';

const JoinPage = () => {
    const [menuVisible, setVisibleMenu] = useState(false);
    return (
        <div>
            <div className="max-w-full text-base overflow-x-hidden mx-auto bg-black">
                <PageContent background="#000">
                    <JoinUsText />
                </PageContent>
            </div>
            <MenuItem visible={menuVisible} color={'#000'} closeMenu={() => setVisibleMenu(false)} />
            <Overlay opened={menuVisible} gotoContact={() => console.log('scrolled')} ascentColor={'#fff'} hidden openMenu={() => setVisibleMenu(!menuVisible)} />
        </div>
    );
};

export default JoinPage;