import "tailwindcss/tailwind.css";
import React, { useState } from "react";
import PageContent from "../../components/page-content";
import MenuItem from "../../components/menu";
import Overlay from "../../components/overlay";
import TeamText from "../../components/Team/team-text";

function Team() {
  const [menuVisible, setVisibleMenu] = useState(false);
  const [scrollIndex, setIndex] = React.useState(0);
  const teamMembers = [
    {
      name: "Tsotne Migriauli",
      text: "Founder and CEO",
      image: "./images/1242.png",
      mobileImage: "./images/12412.png",
    },
    {
      name: "Goga Pangani",
      text: "Head of Back-End Team",
      image: "./images/123.png",
      mobileImage: "./images/7565.png",
    },
    {
      name: 'Giorgi Macharashvili', 
      text: 'Head of Front-End Team', 
      image: './images/Mask Group 6.png', 
      mobileImage: './images/234235.png'
    },
    {
      name: "Irakli Beridze",
      text: "Head of Design Team",
      image: "./images/Mask Group 2.png",
      mobileImage: "./images/12414.png",
    },
    {
      name: "Mamuka Goguadze",
      text: "Head of SEO Team",
      image: "./images/Mask Group 3.png",
      mobileImage: "./images/6326.png",
    },
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      const pageSize = Math.round(
        window.scrollY / window.visualViewport.height
      );
      if (pageSize === scrollIndex) return;
      setIndex(pageSize);
    });
    return () => window.removeEventListener("scroll", () => null);
  }, [scrollIndex]);

  const navigate = (id) => {
    window.scrollTo({
      left: 0,
      top: (id + 1) * window.visualViewport.height,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="max-w-full text-base overflow-x-hidden mx-auto">
        <h1 className="team-header text-white text-6xl -ml-14 laptop:ml-0 fixed laptop:text-8xl huge:text-9xl font-extrabold">
          Team
        </h1>
        {teamMembers.map((member, index) => {
          return (
            <PageContent 
              // shorten={index !== 4} 
              // last={index === 4}
              key={index} 
              itemId={"team"} 
              // zIndex={'1'}
              backgroundImage={"./images/4.jpg"} >
              <TeamText
                text={member.text}
                image={member.image}
                mobile={member.image}
                index={index}
                name={member.name}
              />
            </PageContent>
          );
        })}
      </div>
      <MenuItem
        visible={menuVisible}
        color={"#fff"}
        closeMenu={() => setVisibleMenu(false)}
      />
      <Overlay
        opened={menuVisible}
        scrollNext={() => navigate(scrollIndex)}
        ascentColor={"#fff"}
        openMenu={() => setVisibleMenu(!menuVisible)}
      />
    </>
  );
}

export default Team;
