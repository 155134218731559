import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

const ScreenSix = () => {
    return (
        <ParallaxProvider>
            <div className="flex relative w-full h-full p-5 justify-between">
                <div className="flex flex-1 flex-col">
                    <h1 className="w-2/3 pt-20 text-3xl tablet:text-5xl laptop:text-6xl huge:text-7xl desktop:text-8xl font-extrabold flex flex-col">
                        <span>Flexibility wins</span>
                        <span className='laptop:-mt-10'>every time</span>
                    </h1>
                    <article className="w-full tablet:pt-10 laptop:pt-24 font-semibold laptop:w-3/5 font-openSans text-xl tablet:text-xl laptop:text-2xl">
                        In a rapid-changing world, nurturing our ability to adapt is important to us. From working remotely to adapting to our clients process, flexibility is one of our defining values.
                    </article>
                </div>
                <div className="absolute hidden laptop:block right-0 bottom-0">
                    <Parallax y={[-20, 0]}>
                        <img style={{maxHeight: "70vh"}} className="laptop:max-w-xs medium:max-w-sm huge:max-w-full" src={'./images/horse.png'} alt={"horse"}/>
                    </Parallax>
                </div>
            </div>
        </ParallaxProvider>
    )
}

export default ScreenSix;