import { Parallax } from 'react-scroll-parallax';

const HomeText = ({header, subheader, image, text, left, top}) => {
    return (
        <>
            <div style={{height: '100%'}} className="w-ful relative overflow-hidden z-10 h-4/6 flex flex-col justify-center laptop:flex-row items-center gap-8 laptop:gap-2 laptop:justify-between p-5">
                <div className="user-select-none block relative z-10 leading-relaxed font-extrabold laptop:w-2/5 w-full text-xl mobile-l:text-5xl laptop:text-5xl medium:text-6xl desktop:text-7xl">
                    <h1>{header}</h1>
                    <h1>{subheader}</h1>
                </div>
                <div className='hidden tablet:block' style={{position: 'absolute', left, top, width: '18vw', zIndex: 0}}>
                    <Parallax
                        y={[-20, 20]}
                        slowerScrollRate
                        tag="figure">
                            <img src={image} className='user-select-none' alt={'possible text'} />
                    </Parallax>
                </div>
                <div className='flex tablet:hidden' style={{position: 'absolute', right: 0, top, width: '200px', zIndex: 0}}>
                    <Parallax
                        y={[-20, 20]}
                        slowerScrollRate
                        tag="figure">
                            <img src={image} className='user-select-none' alt={'possible text'} />
                    </Parallax>
                </div>
                <article className="w-full z-10 laptop:w-2/5 text-xl mobile-l:text-2xl">
                    {text}
                </article>
            </div>
        </>
    )
}

export default HomeText;