import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

const ScreenSeven = () => {
    return (
        <ParallaxProvider>
            <div className="flex relative w-full h-full p-5 justify-bettween">
                <div className="flex flex-1 flex-col gap-2 justify-end items-end">
                    <h1 className="tablet:pb-5 laptop:pb-12 font-extrabold flex text-3xl tablet:text-5xl laptop:text-6xl huge:text-8xl flex-col">
                        In trust we trust
                    </h1>
                    <article className="w-full pb-28 laptop:w-7/12 text-right font-openSans text-xl tablet:text-xl laptop:text-2xl">
                        It would be impossible to run Softchef the way we do if we did not  place so much trust in our people, Everything we do is based on mutual trust, which we trust is the right thing to do!                    </article>
                </div>
                <div className="absolute hidden laptop:block left-10 bottom-5">
                    <Parallax y={[-10, 0]} x={[0, 0]}>
                        <img style={{maxHeight: "50vh"}} className="laptop:max-w-xs medium:max-w-full" src={'./images/blue.png'} alt={"blue"}/>
                    </Parallax>
                </div>
            </div>
        </ParallaxProvider>
    )
}

export default ScreenSeven;