const Team = () => {

    const openUrl = (url) => {
        window.open(url , '_blank', 'noopener', 'noreferrer');
    }

    return (
        <div className="mobile-l:flex mobile-l:justify-center">
            <div className="w-full laptop:w-4/5">
                <article className="text-center py-24 capitalize font-semibold text-xl tablet:text-3xl">
                    Our softs have surprised various industries such as Universities, Real estates, Finances, Manufacturers and many others.
                </article>
                <div className="companies user-select-none justify-between">
                    <img onClick={() => {openUrl('https://www.tbcbank.ge/')}} src={'./images/tbc.png'} alt="Agrarian Uni"/>
                    <img onClick={() => {openUrl('https://freeuni.edu.ge')}} src={'./images/freeUniLogo.png'} alt="Free Uni"/>
                    <img onClick={() => {openUrl('https://agruni.edu.ge')}} src={'./images/agrUniLogo.png'} alt="Agrarian Uni"/>
                    <img onClick={() => {openUrl('https://cezanne.ge/')}} src={'./images/CEZANNE.png'} alt="CEZANNE"/>
                    <img onClick={() => openUrl('https://www.losch-meyer.de/')} src={'./images/Losch&Meyer.png'} alt="Losch & Meyer"/>
                    <img onClick={() => {openUrl('https://premiumhouse.ge/')}} src={'./images/Premiumhouse.png'} alt="Premium House"/>
                    <img onClick={() => {openUrl('https://dtmu.ge/')}} src={'./images/DTMU.png'} alt="DTMU"/>
                    <img onClick={() => {openUrl('https://www.bluegarden.ge/en/')}} src={'./images/Bluegarden.svg'} alt="Bluegarden"/>
                    <img onClick={() => {openUrl('https://www.fbh-equestrian.center/')}} src={'./images/FBH.png'} alt="FBH"/>
                </div>
            </div>
        </div>
    )
}

export default Team;