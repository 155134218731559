import AboutView from '../../components/about';
import 'tailwindcss/tailwind.css'
import React from "react";

const About = () => {

    React.useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <>
            <div className="max-w-full text-base overflow-x-hidden mx-auto">
                <AboutView />
            </div>
        </>
    )
}

export default About;